import './Home.styles.css';

import { useState } from 'react';

import HomeHeader from "./components/HomeHeader.component";

import CapacityIcon from '../../assets/icons/user-group.svg';
import PriceIcon from '../../assets/icons/money-bill.svg';
import PhoneIcon from '../../assets/icons/phone.svg';
import EnvelopeIcon from '../../assets/icons/envelope.svg';
import LocationIcon from '../../assets/icons/location.svg';

import ROOMS from './Rooms';

import RoomDetails from './components/RoomDetails.component';

function Home() {

    return (
        <div>
            <HomeHeader />

            <div className='description'>
                <p>Vendégházunk a strandtól és a központtól pár száz méterre helyezkedik el, egy nyugodt mellékutcában, innen egy rövid sétával a településen minden elérhető. Éttermünk a szálláshely közelében található, ahol magyaros és házias ízekkel várunk minden kedves vendégünket, akik nálunk szeretnének pihenni.</p>
            </div>

            <div className='card'>
                <h2 className="card-title">Történetünk</h2>
                <p className='card-content'>Vendégházunk 2012 óta fogad vendégeket. Családi vállalkozásunk alapjait szüleim teremtették meg, amely a helyi éttermünk megnyitásával kezdődött 1986-ban. Sok munkának és törődésnek köszönhetően indulhatott el később a vendégház is, ami azóta is nagy szeretettel és odafigyeléssel várja a vendégeit.</p>
            </div>

            <div id="rooms" className='card'>
                <h2 className="card-title">Szobáink</h2>
                <div id='rooms'>
                    {ROOMS.map((room) => (
                        <div key={room.id}>
                            <RoomDetails room={room} />
                        </div>
                    ))}
                </div>
            </div>

            <div id="prices" className='card'>
                <h2 className='card-title'>Árlista</h2>
                <p style={{ textAlign: 'center', color: 'gray' }}>Az előszezon áprilistól májusig, a főszezon júniustól augusztusig<br></br>az utószezon pedig szeptembertől októberig értendő.</p>
                <table className='price-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th style={{ textAlign: 'center' }}>Standard</th>
                            <th style={{ textAlign: 'center' }}>Családi</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th style={{ textAlign: 'right' }}>Előszezon</th>
                            <td>21.000 Ft</td>
                            <td>36.000 Ft</td>

                        </tr>
                        <tr>
                            <th style={{ textAlign: 'right' }}>Főszezon</th>
                            <td>26.000 Ft</td>
                            <td>45.000 Ft</td>
                        </tr>
                        <tr>
                            <th style={{ textAlign: 'right' }}>Utószezonban</th>
                            <td>21.000 Ft</td>
                            <td>36.000 Ft</td>
                        </tr>
                    </tbody>
                </table>
                <p style={{textAlign: 'center'}}>Pótágy: 5.000ft / fő / éj</p>
            </div>

            <div id="contact" className='card' style={{ background: '#f7f7f7', paddingTop: '24px', paddingBottom: '50px' }}>
                <h2 className='card-title'>Kapcsolat</h2>
                <div className='contact-info'>
                    <img className='contact-info-icon' src={PhoneIcon} />
                    <p className='contact-info-text'>+36 70 882 27 99</p>
                </div>
                <div className='contact-info'>
                    <img className='contact-info-icon' src={EnvelopeIcon} />
                    <p className='contact-info-text'>hatodvendeghaz@gmail.com</p>
                </div>
                <div className='contact-info'>
                    <img className='contact-info-icon' src={LocationIcon} />
                    <p className='contact-info-text'>8313  Balatongyörök. Üst Gyula utca 41.</p>
                </div>
            </div>
        </div>
    );
}

export default Home;